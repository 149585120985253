import { PriceUtils } from '@wix/bookings-uou-mappers';
import {
  ServiceVariant,
  ServiceOptionType,
  ServiceOptionsAndVariants,
} from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';

import { CalendarContext } from '../context/contextFactory';
import { Optional } from '../../types/types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { MultiDynamicPriceInfo } from '@wix/bookings-uou-types/src';
import { SlotAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import type { CalendarServiceSelections } from '@wix/bookings-catalog-calendar-viewer-utils';
import { Service } from '@wix/ambassador-bookings-services-v2-service/build/cjs/types.impl';
import {
  getServiceCurrency,
  getServicePrice,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import { DO_NOT_CARE_STAFF } from '../../constants/constants';

export const getDynamicPricingInfo = ({
  selectedSlot,
  servicesSelections,
  serviceVariantsMap,
  isAssignStaffVariantToSelectionsWithAnyStaffEnabled,
}: {
  servicesSelections: CalendarServiceSelections[];
  selectedSlot: SlotAvailability;
  serviceVariantsMap: CalendarState['serviceVariantsMap'];
  isAssignStaffVariantToSelectionsWithAnyStaffEnabled: boolean;
}): MultiDynamicPriceInfo => {
  const { nestedSlots, slot } = selectedSlot;

  isAssignStaffVariantToSelectionsWithAnyStaffEnabled &&
    assignStaffVariantToSelectionsWithAnyStaff({
      servicesSelections,
      selectedSlot,
      serviceVariantsMap,
    });

  if (nestedSlots?.length) {
    return servicesSelections
      .map((selections, index) => ({
        ...selections,
        startDate: nestedSlots[index].start!,
      }))
      .filter((selections) => selections.variants?.length)
      .map(({ id: serviceId, startDate, variants }) => ({
        serviceId,
        startDate,
        dynamicPriceInfo: {
          selectedVariants: variants!,
        },
      }));
  }

  return servicesSelections
    .filter(({ variants }) => variants?.length)
    .map(({ variants, id: serviceId }) => ({
      serviceId,
      startDate: slot?.startDate!,

      dynamicPriceInfo: {
        selectedVariants: variants!,
      },
    }));
};

export const getFormattedPrice = ({
  context,
  state,
  service,
  choiceId,
  optionId,
}: {
  context: CalendarContext;
  state: CalendarState;
  service: Service;
  choiceId: string;
  optionId: string;
}): string => {
  const { businessInfo } = context;
  const { serviceVariantsMap } = state;
  const serviceVariants = serviceVariantsMap[service.id!];
  const regionalSettingsLocale = businessInfo!.regionalSettingsLocale!;
  const variant = getVariantByChoiceIdAndOptionId(
    serviceVariants,
    choiceId,
    optionId,
  );

  if (variant) {
    return PriceUtils.getFormattedCurrency({
      price: Number(variant.price!.value),
      currency: variant.price!.currency!,
      locale: regionalSettingsLocale,
    });
  } else {
    return PriceUtils.getFormattedCurrency({
      price: Number(getServicePrice(service)),
      currency: getServiceCurrency(service)!,
      locale: regionalSettingsLocale,
    });
  }
};

export const getVariantByChoiceIdAndOptionId = (
  serviceVariants: Optional<serviceoptionsandvariants>,
  choiceId: string,
  optionId: String,
): Optional<servicevariant> => {
  return serviceVariants?.variants?.values?.find(
    ({ choices }) =>
      choices?.[0]?.optionId === optionId &&
      (choices?.[0]?.staffMemberId === choiceId ||
        choices?.[0]?.custom === choiceId),
  );
};

export const isServiceVariantWithStaff = (
  serviceVariants: Optional<serviceoptionsandvariants>,
): boolean => {
  return isServiceVariantWithType(
    serviceVariants,
    ServiceOptionType.STAFF_MEMBER,
  );
};

export const findStaffVariant = ({
  serviceVariants,
  staffMemberId,
}: {
  staffMemberId: string;
  serviceVariants: Optional<serviceoptionsandvariants>;
}): Optional<servicevariant> => {
  if (serviceVariants && isServiceVariantWithStaff(serviceVariants)) {
    const choiceId = staffMemberId;
    const optionId = serviceVariants?.variants?.values?.find(
      ({ choices }) => choices?.[0].staffMemberId === staffMemberId,
    )?.choices?.[0].optionId!;
    const selectedVariant = getVariantByChoiceIdAndOptionId(
      serviceVariants,
      choiceId,
      optionId,
    );

    return selectedVariant;
  }
};

export const isServiceVariantWithCustom = (
  serviceVariants: Optional<serviceoptionsandvariants>,
): boolean => {
  return isServiceVariantWithType(serviceVariants, ServiceOptionType.CUSTOM);
};

const isServiceVariantWithType = (
  serviceVariants: Optional<serviceoptionsandvariants>,
  serviceOptionType: ServiceOptionType,
): boolean => {
  return !!(
    serviceVariants &&
    serviceVariants.options?.values?.some(
      ({ type }) => type === serviceOptionType,
    )
  );
};

function assignStaffVariantToSelectionsWithAnyStaff({
  selectedSlot,
  serviceVariantsMap,
  servicesSelections,
}: {
  servicesSelections: CalendarServiceSelections[];
  selectedSlot: SlotAvailability;
  serviceVariantsMap: Record<string, ServiceOptionsAndVariants="">;
}) {
  const { slot, nestedSlots } = selectedSlot;
  const anyStaffSlotIndexes = servicesSelections
    .map((selection, index) =>
      selection.resources.includes(DO_NOT_CARE_STAFF) ? index : null,
    )
    .filter((n) => n !== null) as number[];

  anyStaffSlotIndexes.forEach((index) => {
    const serviceSelections = servicesSelections[index];
    const serviceId = serviceSelections.id;
    const staffMemberId = nestedSlots?.length
      ? nestedSlots[index].resource?.id!
      : slot?.resource?.id!;
    const staffVariant = findStaffVariant({
      serviceVariants: serviceVariantsMap[serviceId],
      staffMemberId,
    });

    if (staffVariant) {
      serviceSelections.variants = [
        { ...staffVariant, numberOfParticipants: 1 },
      ];
    }
  });
}
</string,></serviceoptionsandvariants></serviceoptionsandvariants></servicevariant></serviceoptionsandvariants></serviceoptionsandvariants></servicevariant></serviceoptionsandvariants>